"use strict";

var TYPE_USER = 'user';
var TYPE_CONTRACT = 'contract';
var TYPE_BUILTIN = 'builtin';
var TYPE_NULL = 'null';
var TYPE_UNKNOWN = 'unknown';
var PREFIX_CFX = 'cfx';
var PREFIX_CFXTEST = 'cfxtest';
var PREFIX_NET = 'net';
var NETID_MAIN = 1029;
var NETID_TEST = 1;
var ZERO_ADDRESS_HEX = '0x0000000000000000000000000000000000000000';
var ADMIN_CONTROL = '0x0888000000000000000000000000000000000000';
var SPONSOR_CONTROL = '0x0888000000000000000000000000000000000001';
var STAKING = '0x0888000000000000000000000000000000000002';
var CONFLUX_CONTEXT = '0x0888000000000000000000000000000000000004';
var POS_REGISTER = '0x0888000000000000000000000000000000000005';
module.exports = {
  TYPE_USER: TYPE_USER,
  TYPE_CONTRACT: TYPE_CONTRACT,
  TYPE_BUILTIN: TYPE_BUILTIN,
  TYPE_NULL: TYPE_NULL,
  TYPE_UNKNOWN: TYPE_UNKNOWN,
  PREFIX_CFX: PREFIX_CFX,
  PREFIX_CFXTEST: PREFIX_CFXTEST,
  PREFIX_NET: PREFIX_NET,
  NETID_MAIN: NETID_MAIN,
  NETID_TEST: NETID_TEST,
  ZERO_ADDRESS_HEX: ZERO_ADDRESS_HEX,
  ADMIN_CONTROL: ADMIN_CONTROL,
  SPONSOR_CONTROL: SPONSOR_CONTROL,
  STAKING: STAKING,
  CONFLUX_CONTEXT: CONFLUX_CONTEXT,
  POS_REGISTER: POS_REGISTER
};